import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>AWS Technical Essentials へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/aws-technical-essentials/" target="blank"> AWS Technical Essentials について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">当日の準備</h3>
      <div class="box">
        <h5>受講準備</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・ブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・本コースではマイクの使用は必須ではありません。スピーカー（やヘッドホンなど）を準備いただければ OK です。<br/>
        ・可能であればモニターは複数用意していただくと演習（ラボ）を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・音声が聞こえない方は P. 28 をご確認ください<br/>
        ===== <a href="https://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">【2】受講者ガイド利用手順</a> =====<br/>
        ・コース開始前に <a href="https://evantage.gilmoreglobal.com/" target="blank">こちら</a> からアカウントの作成をお願いします。<br/>
        ・講義で用いる教材は「Student Guide」、演習の手順書は「Lab Guide」となります。<br/>
        ・ただし、スライドの並び替えや取捨選択、補足資料の追加などをしておりますので、教材ではなく Webex の画面を見ながらの受講をお願いします。<br/>
        ・トレーニングの期間中に必ず 1 度は教材への閲覧をお願いします。<br/>
        ・教材は、引き換え後、720 日間閲覧可能です。<br/>
        ※ 稀に、引き換え後にガイドが表示されないことがあります。同期の問題であることが多いので、午後に [更新] ボタンを押してみてください。<br/>
        ====== 【3】受講者ポータルへのアクセス方法 =====<br/>
        ・ログインページは <a href="" target="blank">こちら(XXXXXXXX)</a> です。<br/>
        ・受講者ポータルから、ラボ（ハンズオン）の実施や、教科書の閲覧ができます。<br/>
        ・研修を申し込む際に使用したアカウントでログインをお願いします。<br/>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
        特になし
        </p>
        
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Amazon Web Services の紹介</td>
            <td><span class="point_box"><b>ラボ1</b></span>AWS Identity and Access Management の紹介</td>
          </tr>
          <tr>
            <td>2</td>
            <td>AWS コンピューティング</td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>AWS ネットワーキング</td>
            <td><span class="point_box"><b>ラボ2</b></span>Amazon EC2 インスタンスでの VPC の作成とウェブアプリケーションの起動</td>
          </tr>
          <tr>
            <td>4</td>
            <td>AWS ストレージ</td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>データベース</td>
            <td><span class="point_box"><b>ラボ3</b></span>VPC を作成し、その中でウェブアプリケーションを起動する</td>
          </tr>
          <tr>
            <td>6</td>
            <td>モニタリング、ロードバランシング、スケーリング</td>
            <td><span class="point_box"><b>ラボ4</b></span>アプリケーションの高可用性を設定する</td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>👇本日作っていただく Web アプリケーション👇</h5>
        <a href="http://web-application-alb-2119867538.ap-northeast-1.elb.amazonaws.com/#/" target="blank">Employee Directory App</a>
        <hr/>
        <h5>モジュール1（Amazon Web Services の紹介）</h5>
        ・<a href="https://aws.amazon.com/jp/cloud/" target="blank">クラウド (クラウドサービス) とは？</a><br/>
        ・<a href="https://aws.amazon.com/jp/aws-ten-reasons/" target="blank">AWS の クラウドが選ばれる 10 の理由</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/global-infrastructure/" target="blank">グローバルインフラストラクチャ</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/global-infrastructure/regional-product-services/" target="blank">「AWS リージョン別のサービス」</a><br/>
        ・<a href="https://aws.amazon.com/jp/s3/pricing/" target="blank">Amazon S3 の料金</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/rethinksharedresponsibility/" target="blank">責任共有モデルとは何か、を改めて考える</a><br/>
        ・<a href="https://aws.amazon.com/jp/compliance/data-center/data-centers/" target="blank">AWS のデータセンター</a><br/>
        ・<a href="https://repost.aws/ja/knowledge-center/ec2-not-auth-launch" target="blank">EC2 インスタンスの起動中に「UnauthorizedOperation」というエラーを受け取った後、認証失敗のメッセージをデコードするにはどうすればよいですか？</a>
        <hr/>
        <h5>モジュール2（AWS コンピューティング）</h5>
        ・<a href="https://aws.amazon.com/jp/ec2/" target="blank">Amazon EC2</a><br/>
        ・<a href="https://aws.amazon.com/jp/ec2/instance-types/" target="blank">Amazon EC2 インスタンスタイプ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSEC2/latest/UserGuide/AMIs.html" target="blank">Amazon マシンイメージ (AMI)</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-13_AWS_Summit_Online_2021_CMP01.pdf" target="blank">Amazon EC2ことはじめ　〜あらゆるワークロードに対応する豊富な選択肢とコスト最適化オプション〜</a>
        <hr/>
        <h5>モジュール3（AWS ネットワーキング）</h5>
        ・<a href="https://aws.amazon.com/jp/vpc/" target="blank">Amazon Virtual Private Cloud (Amazon VPC)</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/vpc/latest/userguide/what-is-amazon-vpc.html" target="blank">Amazon VPC とは?</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/vpc/latest/userguide/default-vpc.html" target="blank">デフォルト VPC</a><br/>
        ・<a href="https://cidr.xyz/" target="blank">CIDR.xyz</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/vpc/latest/userguide/VPC_SecurityGroups.html" target="blank">セキュリティグループを使用してリソースへのトラフィックを制御する</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-32_AWS_Summit_Online_2021_NET01.pdf" target="blank">初心者向け Amazon VPC を中心としたネットワークの構成解説！</a><br/>
        <hr/>
        <h5>モジュール4（AWS ストレージ）</h5>
        ・<a href="https://aws.amazon.com/jp/what-is-cloud-object-storage/" target="blank">クラウドオブジェクトストレージとは</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-41_AWS_Summit_Online_2021_STG02.pdf" target="blank">進化した Amazon S3 を最⼤限に活⽤する</a><br/>
        ・<a href="https://awspolicygen.s3.amazonaws.com/policygen.html" target="blank">AWS Policy Generator</a>
        <hr/>
        <h5>モジュール5（データベース）</h5>
        ・<a href="https://aws.amazon.com/jp/dynamodb/" target="blank">Amazon DynamoDB</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/amazondynamodb/latest/developerguide/Introduction.html" target="blank">Amazon DynamoDB とは</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-10_AWS_Summit_Online_2021_DAT01.pdf" target="blank">今⽇からはじめる︕AWS のデータベースと最適なサービスの選び⽅</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/prime-day-2023-powered-by-aws-all-the-numbers/" target="blank">AWS を活用した 2023 年のプライムデー – 数字が示す驚異的なメトリクス</a>
        <hr/>
        <h5>モジュール6（モニタリング、ロードバランシング、スケーリング）</h5>
        ・<a href="https://aws.amazon.com/jp/ec2/autoscaling/" target="blank">Amazon EC2 Auto Scaling</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/autoscaling/plans/userguide/how-it-works.html" target="blank">スケーリングプランの仕組み</a><br/>
        <hr/>
        <h5>その他（順不同）</h5>
        ----- 【トレーニング情報】<br/>
        ・<a href="https://aws.amazon.com/jp/training/classroom/" target="blank">AWS クラスルームトレーニングの概要</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202203/way-to-choose-training/" target="blank">AWS 認定インストラクターによる有償トレーニングコースの選び方</a><br/>
        ----- 【資格関連情報】<br/>
        ・<a href="https://aws.amazon.com/jp/certification/" target="blank"> AWS 認定</a><br/>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから720日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a><br/>
        &nbsp;&nbsp;※👆の資料のコードによる引き換えは現在方式として（当コースの場合）運用が変更されております。前述の「受講者ポータル」より「受講者ガイド」および「ラボガイド」を引き換え下さい。（要 Bookshelf 登録）
      </div>
    </main>
  );
};

export default MainContents;
